import { PayPalButton } from "react-paypal-button-v2";
import React from "react";
export function PayPalBtn(props) {
  const {
    amount,
    currency,
    createSubscription,
    onApprove,
    catchError,
    onError,
    onCancel,
    clientID,
  } = props;
  const paypalKey = clientID;

  const handleSubscription = (data, details) => {
    // Crear la suscripción del cliente aquí
    // ...

    // Realizar el pago al proveedor aquí
    const providerEmail = "proveedor@example.com"; // Dirección de correo electrónico del proveedor
    const providerId = "PROVIDER_ACCOUNT_ID"; // Identificador de la cuenta de PayPal del proveedor
    const amount = 50; // Cantidad a pagar al proveedor

    fetch("/api/pay-provider", {
      method: "POST",
      body: JSON.stringify({
        providerEmail,
        providerId,
        amount,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Pago al proveedor realizado con éxito", data);
      })
      .catch((err) => {
        console.error("Error al realizar el pago al proveedor", err);
      });
  };

  return (
    <PayPalButton
      amount={amount}
      currency={currency}
      createSubscription={(data, details) => createSubscription(data, details)}
      onApprove={(data, details) => handleSubscription(data, details)}
      onError={(err) => onError(err)}
      catchError={(err) => catchError(err)}
      onCancel={(err) => onCancel(err)}
      options={{
        clientId: paypalKey,
        vault: true,
      }}
      style={{
        shape: "rect",
        color: "blue",
        layout: "horizontal",
        label: "subscribe",
      }}
    />
  );
}
