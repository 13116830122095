import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { AsistentesRouter } from "./AsistentesRouter";
// import { startChecking } from "../actions/auth";
import { AuthRouter } from "./AuthRouter";
import { DaycareRouter } from "./DaycareRouter";
import { PayRouter } from "./PayRouter";
// import { PrivateRoute } from "./PrivateRoutes";
import { PublicRoute } from "./PublicRoutes";

export const AppRouter = () => {
  const { checking, uid } = useSelector((state) => state.auth);
  if (checking) {
    return <h5>Espere...</h5>;
  }
  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            path="/auth"
            component={AuthRouter}
            isAuthenticated={!!uid}
          ></PublicRoute>
          <PublicRoute
            path="/payments"
            component={PayRouter}
            isAuthenticated={!!uid}
          ></PublicRoute>
          <PublicRoute
            path="/daycare"
            component={DaycareRouter}
            isAuthenticated={!!uid}
          ></PublicRoute>
          <PublicRoute
            path="/asistentes"
            component={AsistentesRouter}
            isAuthenticated={!!uid}
          ></PublicRoute>
          <Redirect to="/auth/home" />
        </Switch>
      </div>
    </Router>
  );
};
