import React from "react";
import { Switch, Route } from "react-router-dom";
import { RegisterAsisScreen } from "../components/asistentes/RegisterAsisScreen";

export const AsistentesRouter = () => {
  return (
    <div className="auth__main">
      <Switch>
        <Route
          exact
          path="/asistentes/register/:id/:userName/:userLastName/:userMail/:userPhone"
          component={RegisterAsisScreen}
        />
      </Switch>
    </div>
  );
};
