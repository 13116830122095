import React, { useState } from "react";
import bg from "././../../assets/images/bg.jpg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import { useForm } from "../../hooks/useForm/useForm";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export const InvitationScreen = () => {
  useDocumentTitle("Invitación - Acuarela");
  const leftStyle = {
    backgroundImage: "url(" + bg + ")",
  };
  const [validate, setValidate] = useState(false);
  const [sended, setSended] = useState(false);
  const [values, change, reset] = useForm({
    name: "",
    email: "",
    daycare: "",
    phone: "",
    license: "",
  });
  let { name, email, daycare, phone, license } = values;
  const sendInvitation = async (e) => {
    e.preventDefault();
    setValidate(true);
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("daycare", daycare);
    formdata.append("phone", phone);
    formdata.append("license", license);
    formdata.append(
      "link",
      `https://acuarelacore.com/daycare/register/?name=${name}&daycare=${daycare}&email=${email}&phone=${phone}&license=${license}`
    );

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    await fetch(
      "https://bilingualchildcaretraining.com/s/emailInvitation/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    setValidate(false);
    setSended(true);
    reset();
  };
  return (
    <div className="pay">
      <div className="register">
        <div className="left" style={leftStyle}></div>
        <div className="right">
          <img src={supDer_mancha} alt="supDer" className="supDer" />
          <div
            className="center"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: 40,
              width: "100%",
              flex: 1,
            }}
          >
            {sended ? (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <h1
                  style={{
                    marginBottom: 15,
                    fontFamily: "Proxima Nova",
                    fontWeight: 700,
                    fontSize: 30,
                    color: "#140A4C",
                    textAlign: "center",
                  }}
                >
                  Invitación enviada
                </h1>
                <button
                  type="button"
                  onClick={() => setSended(false)}
                  style={{
                    /* Auto layout */
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 12,
                    height: 39,
                    background: "#0CB5C3",
                    borderRadius: 8,
                    color: "#fff",
                    fontFamily: "Proxima Nova",
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                >
                  Enviar otra invitación
                </button>
              </div>
            ) : (
              <form onSubmit={sendInvitation} autoComplete="off" method="POST">
                <h1
                  style={{
                    marginBottom: 15,
                    fontFamily: "Proxima Nova",
                    fontWeight: 700,
                    fontSize: 30,
                    color: "#140A4C",
                    textAlign: "center",
                  }}
                >
                  Enviar invitación
                </h1>
                <span>
                  <label>Nombre de la persona invitada:</label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Nombre de la persona invitada"
                    value={name}
                    onChange={change}
                  />
                </span>
                <span>
                  <label>Correo:</label>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Correo"
                    value={email}
                    onChange={change}
                  />
                </span>
                <span>
                  <label>Nombre del daycare:</label>
                  <input
                    name="daycare"
                    id="daycare"
                    type="text"
                    placeholder="Nombre del daycare"
                    value={daycare}
                    onChange={change}
                  />
                </span>
                <span>
                  <label>Teléfono:</label>
                  <input
                    name="phone"
                    id="phone"
                    type="text"
                    placeholder="Teléfono"
                    value={phone}
                    onChange={change}
                  />
                </span>
                <span>
                  <label>Número de licencia:</label>
                  <input
                    name="license"
                    id="license"
                    type="text"
                    placeholder="Número de licencia"
                    value={license}
                    onChange={change}
                  />
                </span>
                <button
                  type="submit"
                  disabled={validate}
                  style={{
                    /* Auto layout */
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 12,
                    height: 39,
                    background: "#0CB5C3",
                    borderRadius: 8,
                    color: "#fff",
                    fontFamily: "Proxima Nova",
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                >
                  {validate ? "Enviando..." : "Enviar"}
                </button>
              </form>
            )}
          </div>
          <img src={infDer_mancha} alt="infIzq" className="infIzq" />
        </div>
      </div>
    </div>
  );
};
