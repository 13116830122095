import React, { useState } from "react";
import bg from "././../../assets/images/bg.jpg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import { useLocation } from "react-router-dom";
import { useForm } from "../../hooks/useForm/useForm";
import { useDispatch, useSelector } from "react-redux";
import { removeError, setError } from "../../actions/ui";
import useDocumentTitle from "../../hooks/useDocumentTitle";
export const DaycareRegisterScreen = () => {
  useDocumentTitle("Finalizar Registro - Acuarela");
  const leftStyle = {
    backgroundImage: "url(" + bg + ")",
  };
  const dispatch = useDispatch();
  const search = useLocation().search;
  const { msgError, errorType } = useSelector((state) => state.ui);
  const [validate, setValidate] = useState();
  const [sended, setSended] = useState(false);
  const [values, change, reset] = useForm({
    name: new URLSearchParams(search).get("name"),
    email: new URLSearchParams(search).get("email"),
    daycare: new URLSearchParams(search).get("daycare"),
    phone: new URLSearchParams(search).get("phone"),
    license: new URLSearchParams(search).get("license"),
    password: "",
    repeat: "",
  });
  let { daycare, password, name, email, phone, repeat, license } = values;
  const verifyEmail = async (email) => {
    const exist = await fetch(
      `https://acuarelacore.com/api/acuarelausers?email=${email}`
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log("error", error));
    if (exist.length === 0) {
      return true;
    } else {
      dispatch(setError("*El correo ya se encuentra registrado", "emailExist"));
      return false;
    }
  };
  const isFormValid = () => {
    if (
      !daycare ||
      !name ||
      !email ||
      !phone ||
      !license ||
      daycare.length === 0 ||
      name.length === 0 ||
      email.length === 0 ||
      phone.length === 0 ||
      license.length === 0
    ) {
      dispatch(setError("*Campos Obligatorios", "require"));
      return false;
    }
    if (password.length === 0) {
      dispatch(setError("*La contraseña es obligatoria", "passwordRequired"));
      return false;
    } else if (password.length < 6) {
      dispatch(
        setError(
          "*La contraseña es demasiado débil. Min. 6 caracteres",
          "passwordRequired"
        )
      );
      return false;
    } else if (password !== repeat) {
      dispatch(setError("*Las contraseñas no coinciden", "passwordNotSame"));
      return false;
    }
    dispatch(removeError());
    return true;
  };
  const sendInvitation = async (e) => {
    e.preventDefault();
    const emailExist = await verifyEmail(email);
    if (isFormValid() && emailExist) {
      setValidate(true);
      var formdata = new FormData();
      formdata.append("daycare", daycare);
      formdata.append("password", password);
      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("phone", phone);
      formdata.append("license", license);
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      await fetch("https://acuarela.app/s/activate/", requestOptions)
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      setSended(true);
      setValidate(false);
      reset();
    }
  };
  return (
    <div className="pay">
      <div className="register">
        <div className="left" style={leftStyle}></div>
        <div className="right">
          <img src={supDer_mancha} alt="supDer" className="supDer" />
          <div
            className="center"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: 40,
              width: "100%",
              flex: 1,
            }}
          >
            {sended ? (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <h2
                  style={{
                    color: "#140a4c",
                    fontWeight: 700,
                    width: "100%",
                    textAlign: "center",
                    marginBottom: 15,
                  }}
                >
                  Cuenta creada
                </h2>
                <p
                  style={{
                    lineHeight: "22px",
                    textAlign: "center",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    color: "#140a4c",
                    marginBottom: "30px",
                    maxWidth: 480,
                  }}
                >
                  Has creado tu cuenta exitosamente, pronto recibirás un correo
                  de confirmación y links para descargar la aplicación.
                </p>
                <a href="https://acuarela.app/" className="btn fill">
                  Finalizar
                </a>
              </div>
            ) : (
              <form onSubmit={sendInvitation} autoComplete="off" method="POST">
                <h1
                  style={{
                    marginBottom: 15,
                    fontFamily: "Proxima Nova",
                    fontWeight: 700,
                    fontSize: 30,
                    color: "#140A4C",
                    textAlign: "center",
                  }}
                >
                  Finaliza tu registro
                </h1>
                <span>
                  <label>Tu nombre:</label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Tu nombre"
                    value={name}
                    onChange={change}
                  />
                  {errorType === "require" && (
                    <small className="error">{msgError}</small>
                  )}
                </span>
                <span>
                  <label>Nombre de tu Daycare:</label>

                  <input
                    name="daycare"
                    id="daycare"
                    type="text"
                    placeholder="Nombre de tu Daycare"
                    value={daycare}
                    onChange={change}
                  />
                  {errorType === "require" && (
                    <small className="error">{msgError}</small>
                  )}
                </span>
                <span>
                  <label>Correo:</label>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Correo"
                    value={email}
                    onChange={change}
                  />
                  {errorType === "require" && (
                    <small className="error">{msgError}</small>
                  )}
                  {errorType === "emailExist" && (
                    <small className="error">{msgError}</small>
                  )}
                </span>
                <span>
                  <label>Teléfono:</label>
                  <input
                    name="phone"
                    id="phone"
                    type="tel"
                    placeholder="Teléfono"
                    value={phone}
                    onChange={change}
                  />
                  {errorType === "require" && (
                    <small className="error">{msgError}</small>
                  )}
                </span>
                <span>
                  <label>Licencia:</label>
                  <input
                    name="license"
                    id="license"
                    type="tel"
                    placeholder="Licencia"
                    value={license}
                    onChange={change}
                  />
                  {errorType === "require" && (
                    <small className="error">{msgError}</small>
                  )}
                </span>
                <span>
                  <label>Contraseña:</label>
                  <input
                    name="password"
                    id="password"
                    type="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={change}
                  />

                  {errorType === "passwordRequired" && (
                    <small className="error">{msgError}</small>
                  )}
                </span>
                <span>
                  <label>Confirmar contraseña:</label>
                  <input
                    name="repeat"
                    id="repeat"
                    type="password"
                    placeholder="Confirmar contraseña"
                    value={repeat}
                    onChange={change}
                  />
                  {errorType === "passwordNotSame" && (
                    <small className="error">{msgError}</small>
                  )}
                </span>
                <label className="containerCheckbox" for="politics">
                  <input
                    type="checkbox"
                    checked
                    id="politics"
                    name="politics"
                  />
                  <div className="checkmark"></div>
                  <p>
                    He leído y acepto
                    <a
                      rel="noreferrer"
                      href="https://www.bilingualchildcaretraining.com/privacidad-terminos-condiciones"
                      target="_blank"
                    >
                      las políticas de uso de datos personales
                    </a>
                  </p>
                </label>
                <button
                  type="submit"
                  disabled={validate}
                  style={{
                    /* Auto layout */
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 12,
                    height: 39,
                    background: "#0CB5C3",
                    borderRadius: 8,
                    color: "#fff",
                    fontFamily: "Proxima Nova",
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                >
                  {validate ? "Enviando..." : "Enviar"}
                </button>
              </form>
            )}
          </div>
          <img src={infDer_mancha} alt="infIzq" className="infIzq" />
        </div>
      </div>
    </div>
  );
};
