import bg from "././../../assets/images/bg.jpg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import appstore from "././../../assets/images/appstore.png";
import playstore from "././../../assets/images/playstore.png";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export const HomeScreen = () => {
  useDocumentTitle("Home");
  const leftStyle = {
    backgroundImage: "url(" + bg + ")",
  };

  return (
    <div className="register">
      <div className="left" style={leftStyle}>
        <div className="txt">
          <h1>Ayudamos a los daycares a cuidar el futuro</h1>
          <p>
            Reconocemos que la industria del cuidado infantil necesita un
            software que sea amigable y fácil de usar, y al mismo tiempo lo
            suficientemente poderoso como para ayudar a los propietarios y
            operadores de cuidado infantil a manejar las muchas
            responsabilidades y tareas que surgen cada día.
          </p>
          <button className="btn noFill white">Ver más</button>
        </div>
      </div>
      <div className="right">
        <img src={supDer_mancha} alt="supDer" className="supDer" />
        <div className="centerflex">
          <h2 style={{ fontSize: "400%" }}>Encuentrala en:</h2>

          <div className="links">
            <a href="https://apps.apple.com/us/app/acuarela-for-daycares/id1573321954">
              <img src={appstore} alt="App Store" style={{ width: "100px" }} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.acuarela.daycaresapp">
              <img
                src={playstore}
                alt="Play Store"
                style={{ width: "100px" }}
              />
            </a>
          </div>
        </div>
        <img src={infDer_mancha} alt="infIzq" className="infIzq" />
      </div>
    </div>
  );
};
