import React from "react";
import { Link } from "react-router-dom";
import bg from "././../../assets/images/bg.jpg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import arrow from "././../../assets/images/flechas/Flecha_izquierda.svg";
import PhoneInput from "react-phone-number-input";
import { BoxesModal } from "../boxes/BoxesModal";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export const RecoveryPhoneScreen = () => {
  useDocumentTitle("Recuperar contraseña - Acuarela");
  // const [code, setCode] = useState('0000')

  const leftStyle = {
    backgroundImage: `url(${bg})`,
  };
  return (
    <div className="login">
      <div className="left" style={leftStyle}>
        <div className="txt">
          <h1>Ayudamos a los daycares a cuidar el futuro</h1>
          <p>
            Reconocemos que la industria del cuidado infantil necesita un
            software que sea amigable y fácil de usar, y al mismo tiempo lo
            suficientemente poderoso como para ayudar a los propietarios y
            operadores de cuidado infantil a manejar las muchas
            responsabilidades y tareas que surgen cada día.
          </p>
          <button className="btn noFill white">Ver más</button>
        </div>
      </div>
      <div className="right">
        <img src={supDer_mancha} alt="supDer" className="supDer" />
        <img src={infDer_mancha} alt="infIzq" className="infIzq" />
        <form autoComplete="off">
          <Link to="/auth/home" className="backArrow">
            <img src={arrow} alt="back" width="32" height="32" />
          </Link>
          <h2>Ingresa tu e-mail o número para recuperar contraseña</h2>
          <span>
            <label>
              Teléfono:{" "}
              <Link to="/auth/recovery-password">Recuperar con e-mail</Link>
            </label>
            <PhoneInput name="phone" placeholder="Teléfono" />
          </span>
          <Link to="/auth/home" className="bold linkAuth">
            Cancelar
          </Link>
          <button type="submit" className="btn fill">
            Enviar
          </button>
        </form>
      </div>
      <BoxesModal isOpen={true} />
    </div>
  );
};
