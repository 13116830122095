import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";

import bg from "././../../assets/images/bg.jpg";
import infDer_mancha from "././../../assets/images/manchas/infDer_mancha.svg";
import supDer_mancha from "././../../assets/images/manchas/supDer_mancha.svg";

import { removeError, setError } from "../../actions/ui";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../hooks/useForm/useForm";
import { useHistory, useParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export const RegisterPhoneScreen = () => {
  useDocumentTitle("Finalizar Registro - Acuarela");
  const leftStyle = {
    backgroundImage: "url(" + bg + ")",
  };
  const dispatch = useDispatch();

  const { msgError, errorType, loading } = useSelector((state) => state.ui);

  const [formValues, handleInputChange] = useForm({
    password: "",
    repeatPassword: "",
  });
  let { password, repeatPassword } = formValues;
  const [num, setNum] = useState("+12133734253");
  const props = useHistory();

  const { token } = useParams();
  useEffect(() => {
    fetch("http://localhost:1337/acuarelausers/get/invitation/" + token)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.ok) setNum(result.user.phone);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  });

  const isFormValid = () => {
    if (password.length === 0) {
      dispatch(setError("*La contraseña es obligatoria", "passwordRequired"));
      return false;
    } else if (password.length < 5) {
      dispatch(
        setError("*La contraseña es demasiado débil", "passwordRequired")
      );
      return false;
    } else if (password !== repeatPassword) {
      dispatch(setError("*Las contraseñas no coinciden", "passwordNotSame"));
      return false;
    }
    dispatch(removeError());
    return true;
  };

  const handleRegisterPhone = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      fetch(
        `http://localhost:1337/acuarelausers/invitation/register/${token}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            mail: "-1",
            phone: num,
            pass: password,
            token,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.ok) return props.push("/auth/home");
          else console.log("Fallo no se puede proseguir");
        });
    } /*
    const countrySelected = document.querySelector("select[name=phoneCountry]")
      .value;
    if (countrySelected === "ZZ") {
      dispatch(setError("*Selecciona un país.", "selectCountry"));
      return false;
    } else {
      if (isFormValid()) {
        let number = random4Digit();
        dispatch(uiOpenModal(number, countrySelected));
      }
    }*/
  };

  return (
    <div className="register">
      <div className="left" style={leftStyle}>
        <div className="txt">
          <h1>Ayudamos a los daycares a cuidar el futuro</h1>
          <p>
            Reconocemos que la industria del cuidado infantil necesita un
            software que sea amigable y fácil de usar, y al mismo tiempo lo
            suficientemente poderoso como para ayudar a los propietarios y
            operadores de cuidado infantil a manejar las muchas
            responsabilidades y tareas que surgen cada día.
          </p>
          <button className="btn noFill white">Ver más</button>
        </div>
      </div>
      <div className="right">
        <img src={supDer_mancha} alt="supDer" className="supDer" />
        <img src={infDer_mancha} alt="infIzq" className="infIzq" />
        <form onSubmit={handleRegisterPhone}>
          <h2>Registro</h2>
          <span>
            <label>Ingresar con número:</label>
            <PhoneInput
              name="phone"
              placeholder="Teléfono"
              value={num}
              disabled={true}
            />
            {errorType === "selectCountry" && (
              <small className="error">{msgError}</small>
            )}
          </span>
          <span>
            <label>Contraseña:</label>
            <input
              name="password"
              id="password"
              placeholder="Contraseña"
              type="password"
              value={password}
              onChange={handleInputChange}
            />
          </span>
          <span>
            <label>Repetir contraseña:</label>
            <input
              name="repeatPassword"
              id="repeatPassword"
              placeholder="Contraseña"
              type="password"
              value={repeatPassword}
              onChange={handleInputChange}
            />
          </span>
          <button type="submit" className="btn fill" disabled={loading}>
            {loading ? "Registrando..." : "Registrar"}
          </button>
        </form>
      </div>
    </div>
  );
};
